let table = {
    Init : () => {
        table.Search();
    },
    Search : () => {

        /*Jd.off('submit', 'form.table-option-search').on('submit', 'form.table-option-search', function(e){
            e.preventDefault();
            return;
        });*/

        if (J('.table-option-search').length > 0 && J('table > tbody > tr').length > 0) {

            /*Jd.off('keyup', 'input[name="s"]').on('keyup', 'input[name="s"]', function(e){

                let value = (J(this).val()).toUpperCase();
                let $table = J(this).parent().parent().parent().children('table');

                if (value.length > 2) {

                    $table.children('tbody').children('tr').hide();

                    $table.children('tbody').children('tr').each(function(index, item){
                        let trText = (J(item).text()).toUpperCase();
                        if (trText.indexOf(value) != -1) {
                            J(item).show();
                        }
                    });

                } else {
                    $table.children('tbody').children('tr').show();
                }
            });*/
        }
    }
}
module.exports = table;