require('leaflet');

let map = {

    mapInit : false,

    Init : (id) => {

        let mymap = map.MapInit(id);

        let arrayLatLng = [];
        let index = 0, countmarker = 0;

        function clickZoom(e) {
            mymap.setView(e.target.getLatLng(), 20);
        }

        map.GetMarkers(function(markers) {

            if (markers.length) {

                countmarker = countmarker + markers.length;

                for (var i = 0; i < markers.length; i++) {

                    var projectIcon = L.icon({
                        iconUrl: '/img/markers/marker-project.png',
                        iconSize: [48, 48],
                        iconAnchor: [22, 94],
                        popupAnchor: [-3, -76]
                    });

                    new L.marker([markers[i][1], markers[i][2]], {icon: projectIcon}).addTo(mymap).bindPopup(markers[i][0]).on('click', clickZoom);

                    arrayLatLng.push([markers[i][1], markers[i][2]]);

                    index++;
                }
            }

            map.SocietyMarker(function(smarkers) {

                if (smarkers.length) {
    
                    countmarker = countmarker + smarkers.length;
                    
                    for (var i = 0; i < smarkers.length; i++) {
    
                        var societyIcon = L.icon({
                            iconUrl: '/img/markers/marker-society.png',
                            iconSize: [68, 68],
                            iconAnchor: [22, 94],
                            popupAnchor: [-3, -76]
                        });
    
                        new L.marker([smarkers[i][1], smarkers[i][2]], {icon: societyIcon}).addTo(mymap).bindPopup(smarkers[i][0]).on('click', clickZoom);
    
                        arrayLatLng.push([smarkers[i][1], smarkers[i][2]]);
                        
                        index++;
                    }
                }
    
                if (index ===  countmarker) {
                    var bounds = new L.LatLngBounds(arrayLatLng);
                    mymap.fitBounds(bounds);
                }
            });
        });
    },

    MapInit : (id, params={center: [43.3, 5.4], zoom: 11}) => {
        var mymap = L.map(id, params);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(mymap);
        
        return mymap;
    },

    AddressEdit : (id) => {

        if (J('input[name="lat"]').length && J('input[name="lng"]').length && !map.mapInit) {

            let mymap = map.MapInit(id, {center: [43.3, 5.4], zoom: 11, zoomControl: false, scrollWheelZoom: false, dragging: false});

            let lat = parseFloat(J('input[name="lat"]').val());
            let lng = parseFloat(J('input[name="lng"]').val());

            if (lat && lng) {

                let arrayLatLng = [];

                var Icon = L.icon({
                    iconUrl: '/img/markers/marker-project.png',
                    iconSize: [48, 48],
                    iconAnchor: [22, 94],
                    popupAnchor: [-3, -76]
                });

                arrayLatLng.push([lat, lng]);

                L.marker([lat, lng], {icon: Icon}).addTo(mymap);

                var bounds = new L.LatLngBounds(arrayLatLng);
                mymap.fitBounds(bounds);
            }

            map.mapInit = true;
        }
    },

    CustomerEdit : (id) => {

        if (J('input[name="lat"]').length && J('input[name="lng"]').length) {

            let mymap = map.MapInit(id, {center: [43.3, 5.4], zoom: 11, zoomControl: false, scrollWheelZoom: false, dragging: false});

            let lat = parseFloat(J('input[name="lat"]').val());
            let lng = parseFloat(J('input[name="lng"]').val());

            if (lat && lng) {

                let arrayLatLng = [];

                var Icon = L.icon({
                    iconUrl: '/img/markers/marker-project.png',
                    iconSize: [48, 48],
                    iconAnchor: [22, 94],
                    popupAnchor: [-3, -76]
                });

                arrayLatLng.push([lat, lng]);

                L.marker([lat, lng], {icon: Icon}).addTo(mymap);

                var bounds = new L.LatLngBounds(arrayLatLng);
                mymap.fitBounds(bounds);
            }

            map.mapInit = true;
        }
    },

    SocietyMarker : (callback) => {
        let Ajax = J.ajax({
            url : '/admin/params/society/marker',
            method: 'POST',
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        })

    },

    GetMarkers : (callback) => {

        let Ajax = J.ajax({
            url : '/admin/projects/markers',
            method: 'POST',
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        })
    }
}
module.exports = map;