let tab = {

    Init : () => {

        if (J('.main-tab-btn').length) {

            Jd.off('click', '.main-tab-btn').on('click', '.main-tab-btn', function(){

                let tab = J(this).attr('data-tab');
                J('.main-tab-btn').removeClass('main-tab-btn-active');
                J(this).addClass('main-tab-btn-active');

                J('.main-tab-content').removeClass('main-tab-content-visible');
                J('.main-tab-content[data-content="'+tab+'"]').addClass('main-tab-content-visible');

                window.location.hash = tab;

                if (J('#project_edit').length && tab === 'address') {
                    require('./map').AddressEdit('address_map');
                }
                return;
            });

            Jd.off('click', '.tab-content-view-btn').on('click', '.tab-content-view-btn', function(){

                let $that = J(this)
                let view = J(this).attr('data-view');

                if (J('.tab-content-view[view="'+view+'"]').length) {
                    $that.parent('.tab-content-view-btn-container').parent('.tab-content-view').addClass('tab-content-view-hidden');
                    J('.tab-content-view[view="'+view+'"]').removeClass('tab-content-view-hidden')
                }
            });

            if (window.location.hash) {
                let hash = (window.location.hash).replace('#', '');
          
                if (J('.main-tab-content[data-content="'+hash+'"]').length && hash != 'todo-tables') {
                   
                    J('.main-tab-btn[data-tab="'+hash+'"]').trigger('click');

                    if (J('#project_edit').length && hash === 'adresse') {
                        require('./map').AddressEdit('address_map');
                    }
                }
            }
        }
    }
}
module.exports = tab;