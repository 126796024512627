let form = {

    Init : () => {
        form.File();
        form.TextArea();
        form.Price();
        form.CustomFieldActions();
    },

    CustomField : (inTable) => {

        if (J('#custom_field_'+inTable).length) {

            J('#custom_field_'+inTable).append(`
                <div class="custom-field-container" style="display:flex;gap:5px;align-items:center;position:relative;margin: 5px 0;">
                    <div style="width:50%;"><input type"text" name="custom_field_label[]" placeholder="label" required/></div>
                    <div style="width:50%;"><input type"text" name="custom_field_value[]" placeholder="valeur" required/></div>
                    <div class="custom-field-remove"><i class="fas fa-minus"></i></div>
                </div>
            `);
        }
    },

    CustomFieldActions : () => {

        Jd.off('click', '#custom_field_settlements_add').on('click', '#custom_field_settlements_add', function(){
            form.CustomField('settlements');
        });

        Jd.off('click', '#custom_field_quotations_add').on('click', '#custom_field_quotations_add', function(){
            form.CustomField('quotations');
        });

        Jd.off('click', '#custom_field_commands_add').on('click', '#custom_field_commands_add', function(){
            form.CustomField('commands');
        });

        Jd.off('click', '#custom_field_invoices_add').on('click', '#custom_field_invoices_add', function(){
            form.CustomField('invoices');
        });

        Jd.off('click', '.custom-field-remove').on('click', '.custom-field-remove', function(){
            J(this).parent('.custom-field-container').remove();
        });
    },

    File : () => {
        if (J('input[type="file"]').length && J('.label-file-name').length) {

            Jd.off('change', 'input[type="file"]').on('change', 'input[type="file"]', function(){

                let filename = '';

                if (J(this).attr('multiple')) {

                    var fileList = document.getElementById(J(this).attr('id')).files;

                    for(var i = 0; i < fileList.length; i++) {
                        console.log(fileList[i])
                        filename = filename + ' ' +(fileList[i].name).replace(/C:\\fakepath\\/i, '') + ', ';
                    }
                } else {
                    filename = J(this).val();
                    filename = filename.replace(/C:\\fakepath\\/i, '');
                }

                J(this).parent().children('.label-file-name').text(filename);

                ///form.ImagePreview(this);

                if (J(this).parent().children('.label-file-image').length) {
                    J(this).parent().children('.label-file-image').remove();
                }
                return;
            });
        }
    },

    ImagePreview : (input) => {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {

                if (J('.label-file-image').length) {
                    J('.label-file-image').html('<img id="file_preview" src="'+e.target.result+'">');
                } else {
                    J('<div class="label-file-image"><img id="file_preview" src="'+e.target.result+'"></div>').insertBefore('.label-file-name');
                }

                if (J('.label-file-image').length) {
                    J('.label-file-image').html()
                }
            };
            reader.readAsDataURL(input.files[0]);
        }
    },

    TextArea : () => {

        if (J("textarea").length) {

            J("textarea").each(function () {
                this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
            }).on("input focus", function () {
                this.style.height = "auto";
                this.style.height = (this.scrollHeight) + "px";
            });
        }
    },

    Price : () => {

        let $ttc    = J('input[name="price_ttc"]');
        let $ht     = J('input[name="price"]');
        let $vat    = J('select[name="vat"]');

        if ($ttc.length && $ht.length && $vat.length) {

            Jd.off('change', 'input[name="price_ttc"]').on('change', 'input[name="price_ttc"]', function(){
                let value = parseFloat(J(this).val());
                if (value) {
                    let vat = $vat.val()
                    let divvat = '1'+vat;
                    $ht.val( value - (value / parseInt(divvat)) * parseInt(vat) );
                } else {
                    $ht.val('');
                }
            });

            Jd.off('change', 'input[name="price"]').on('change', 'input[name="price"]', function(){
                let value = parseFloat(J(this).val());
                if (value) {
                    $ttc.val( (value * (parseInt($vat.val()) / 100) + value ) * 1 );
                } else {
                    $ttc.val('');
                }
            });

            Jd.off('change', 'select[name="vat"]').on('change', 'select[name="vat"]', function(){
                let vat = parseInt(J(this).val());
                if (vat) {
                    if ($ht.val()) {
                        $ttc.val( (parseFloat($ht.val()) * (parseInt(vat) / 100) + parseFloat($ht.val()) ) * 1 );
                    }else if ($ttc.val()) {
                        let divvat = '1'+vat;
                        $ht.val( parseFloat($ttc.val()) - (parseFloat($ttc.val()) / parseInt(divvat)) * parseInt(vat) );
                    }
                }
            });
        }
    }
}
module.exports = form;