require('jquery-ui-dist/jquery-ui');
let move = {

    TableRow : () => {

        if (J('.table-grip-move').length) {

            J( "tbody" ).sortable({
                handle: ".table-grip-move",
                update: function( event, ui ) {
                    J(this).children().each(function(index) {
                        let position = index + 1;
                        J(this).attr('position', position).removeAttr('class style');
                        move.TablePositionRow({'service_id' : J(this).attr('service-id'), 'position' : position}, function(result){

                        });
                    });
                },
                stop: function( event, ui ) {
                    
                }
            }).disableSelection();
        }
    },

    TablePositionRow : (data, callback) => {

        let Ajax = J.ajax({
            url : '/admin/quotations/ajax/table/row/update/position',
            method: 'POST',
            data: data,
            dataType: 'JSON'
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            return callback(error);
        });
    },

    DashboardWidget : () => {

        J( ".dashboard-col" ).sortable({
            connectWith: ".dashboard-col",
            handle: ".fa-arrows-alt"
        }).disableSelection();
    }
}
module.exports = move;